
		import Async from "../../../node_modules/.pnpm/@preact+async-loader@3.0.1_preact@10.7.1/node_modules/@preact/async-loader/async.js";

		function load(cb) {
			require.ensure([], function (require) {
				var result = require("!!../../../node_modules/.pnpm/babel-loader@8.2.3_60b7ed408fec1293d95f86d9ceaa88ca/node_modules/babel-loader/lib/index.js??ref--4!./index.js");
				typeof cb === 'function' && cb(result);
			}, "route-home");
		}

		export default Async(load);
	